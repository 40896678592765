#hms-wrapper {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 0;
    background-color: transparent;
    min-width: 220px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}
#hms-wrapper svg {
    max-height: 100%;
    width: 100%;
    margin: 0;
}
.hms-gob, .hms-goa{
    fill-opacity: 0;
    -webkit-transition: fill-opacity 0.1s ease;
    -moz-transition: fill-opacity 0.1s ease;
    -o-transition: fill-opacity 0.1s ease;
    transition: fill-opacity 0.1s ease;
    cursor: pointer;
}
.hms-gob:hover, .hms-goa:hover{
    fill-opacity: 0.3;
}
.hms-gob:active, .hms-goa:active{
    fill-opacity: 0.7;
}
#tip-hms {
    font: 14px/16px Trebuchet MS, Helvetica, Arial, sans-serif;
    display: none;
    max-width: 40%;
    padding: 5px;
    border: 1px solid #404040;
    color: #404040;
    z-index: 1000;
    float: left;
    position: absolute;
    background: rgba(265, 265, 265, 0.9);
    word-break: keep-all;
        box-shadow:1px 2px 4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow:1px 2px 4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow:1px 2px 4px rgba(0, 0, 0, 0.5);
}
#tip-hms:empty {
    padding: 0;
    visibility: hidden;
}
#tip-hms p {
    margin: 0!important;
    color: #404040!important;
}
#tip-hms img {
    float: left;
    padding: 3px;
    max-width: 100%;
}
@media screen and (max-width: 320px){
    #hms-wrapper svg {
        height:530px;
    }
    #tip-hms {
        max-width: 40%;
    }
    #tip-hms img {
        max-width: 95%;
    }
}
@media screen and (max-width: 400px) and (min-width: 321px) {
    #hms-wrapper svg {
        height:560px;
    }
}
@media screen and (max-width: 480px) and (min-width: 401px) {
    #hms-wrapper svg {
        height:600px;
    }
}
@media screen and (max-width: 568px) and (min-width: 481px) {
    #hms-wrapper svg {
        height:640px;
    }
}
@media screen and (max-width: 685px) and (min-width: 569px) {
    #hms-wrapper svg {
        height:680px;
    }
}
@media screen and (max-width: 767px) and (min-width: 686px) {
    #hms-wrapper svg {
        height:690px;
    }
}
@media screen and (min-width: 768px) {
    #hms-wrapper svg {
        height:700px;
    }
}