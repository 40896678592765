@import "bootstrap/dist/css/bootstrap.min.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.App {
  text-align: center;
}

/* html {
  min-width: 570px;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.vh-50 {
  height: 53vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.font-poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.font-poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  /* background: var(
    --grad,
    linear-gradient(135deg, #53972c 0%, #acdd59 100%)
  ) !important; */
  font-family: "Poppins", sans-serif;
}

/* Generic check box styles */
.modal-open {
  padding-right: 0 !important;
}

.modal-backdrop.show {
  opacity: 0 !important;
}

.form-check-input[type="checkbox"] {
  border: 2px solid #e30020 !important;
  width: 20px !important;
  height: 20px !important;
}

.form-check-input[type="checkbox"]:checked {
  background-color: #e30020 !important;
}

.form-control:focus {
  /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(122, 104, 104, 0.6); */
  box-shadow: none;
}

.form-control.is-invalid:focus {
  box-shadow: none;
  /* Replace with your desired styles */
}

.form-select:focus {
  box-shadow: none;
}

::-webkit-scrollbar {
  width: 7px;
  border-radius: 7px !important;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #cacecd;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.main-container {
  max-width: 3200px;
  height: 100%;
  padding: 2rem 6rem 2rem 6rem;
  background: none !important;
}

.chat-history-box {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.history-buttons:hover {
  /* background-color: #a6aba9; */
  background: linear-gradient(to left, #a6aba9, #ffffff);
}

/* Stepper styles */

.custom-step-root {
  background-color: transparent;
}

.custom-modal-style .modal-content {
  max-height: 80vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
    border-radius: 50%;
  }
}

.custom-modal-style.custom-width .modal-content {
  width: 650px;
}

.custom-step-icon {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  padding: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #969c9a !important;
  border: 1px solid #969c9a;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.custom-step-icon.active {
  background-color: #faba31 !important;
  width: 63px;
  height: 63px;
  border-color: transparent;
  color: #fff !important;
}

.main-active {
  border: 3px solid #faba31 !important;
  width: 75px;
  height: 75px;
  padding: 3.8px;
  border-radius: 50%;
}

.custom-step-icon.active img {
  filter: brightness(0) invert(1) !important;
}

.MuiStepLabel-iconContainer.custom-step-completed .custom-border::before {
  content: "\2713";
  font-size: 26px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 14px;
  right: 37%;
  color: white;
}

.custom-step-completed .custom-border {
  background: green;
  border-radius: 50%;
  position: relative;
}

.custom-step-completed span img {
  display: none !important;
}

.subTitle {
  font-size: 16px !important;
  color: #636967 !important;
  font-weight: 400 !important;
  text-align: center;
}

.title {
  font-size: 20px !important;
  color: #191a1a !important;
  text-align: center;
}

.css-zpcwqm-MuiStepConnector-root,
.css-15oeqyl {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: absolute;
  top: 35px !important;
  left: calc(-39% + 20px) !important;
  right: calc(61% + 20px) !important;
}

.autoplay-btn {
  width: 200px;
  height: 200px;
  border: 5px solid transparent;
  border-radius: 50%;
  /* border-image: linear-gradient(to right, #53972C, #ACDD59) 1; */
  border: 5px solid #e30020;
  color: #e30020;
  font-size: 40px;
  font-weight: 500;
  background-color: transparent;
}

.content-heading {
  color: #53972c !important;
  font-weight: 600 !important;
  font-size: 24px !important;
}

.content-stats {
  color: #2e3130 !important;
  font-size: 24px !important;
  font-weight: 400 !important;
}

.content-icon img {
  filter: invert(45%) sepia(76%) saturate(378%) hue-rotate(54deg)
    brightness(97%) contrast(97%) !important;
}

input::placeholder {
  font-size: 14px;
  /* Adjust the font size as needed */
  color: #999;
  /* Optional: Customize the color */
}

.input-field {
  outline: unset !important;
  padding: 8px 1.5rem;
  display: flex;
  align-items: baseline;
  overflow: hidden;
}

.upload-img {
  background: white;
  /* justify-content: center; */
  border-radius: 0px 0px 1.375rem 1.375rem;
}

textarea {
  outline: unset !important;
  padding: 1rem 1rem;
}

.scroll-hide {
  width: 100%;
  /* height: 100%; */
  max-height: 300px !important;
  overflow: auto;
  overflow-x: hidden;
}

.scroll-hide-content {
  padding-top: 20px;
  /* padding-left: 4.25rem;
  padding-right: 4.25rem; */
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: -5px;
  resize: none;
}

.stepper-container {
  display: flex !important;
  justify-content: space-around !important;
  flex-wrap: wrap !important;
  padding: 20px;
}

@media (max-width: 1500px) {
  .css-z7uhs0-MuiStepConnector-line {
    display: none !important;
  }
}

@media (max-width: 992x) {
  .main-container {
    padding: 2rem !important;
  }
}

@media (max-width: 769px) {
  .main-container {
    padding: 2rem;
  }
}

@media (max-width: 600px) {
  .main-container {
    padding: 2rem 1rem 2rem 1rem;
  }

  .stepper-container {
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 10px;
  }
}

@media (max-width: 450px) {
  .main-container {
    padding: 1rem;
  }

  .p-5 {
    padding: 1rem !important;
  }

  .pt-5 {
    padding-top: 1rem !important;
  }

  .input-field {
    padding: 0;
  }
}

.modal-body-content {
  margin-top: -4px;
}

/* .video-container {
  position: relative;
  transform: scale(1.25);
  transform-origin: center;
  overflow: hidden;
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -o-transform: scale(1.25);
  background: #fff;
} */

.modal-body-content {
  margin-top: -4px;
}

.bg-video {
  height: 100%;
  /* height: calc(45rem + 100vh); */
  width: 100%;
  position: fixed;
  object-fit: cover;
  object-position: top;
  left: 0;
  top: 0;
  z-index: -1;
}

.mini-background {
  height: -webkit-fill-available;
  width: 100%;
  position: fixed;
  object-fit: cover;
  object-position: top;
  left: 0;
  top: 0;
  z-index: -1;
}

.bottom-arrow:before {
  content: "";
  border-top: 20px solid #cc0000;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}

.chat-symptoms-img {
  max-width: 350px;
  min-width: 200px;
  display: flex;
  justify-content: center;
}

.symptoms-img {
  position: relative;
  max-width: 120px;
  margin-left: 5rem;
  display: flex;
  justify-content: center;
}

.symptoms-img:hover {
  opacity: 1;
}

.remove-icon {
  position: absolute;
  top: -8px;
  right: -8px;
  background: red;
  border-radius: 50%;
  color: white;
  z-index: 999;
}

.eye-icon-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ant-color-text-light-solid);
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  color: white;
  transition: opacity var(--ant-motion-duration-slow);
}

/* @media only screen and (min-width: 768px) and (max-width: 1400px) and (orientation: landscape) {
  .chat-box-a,
  .bg-video {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .chat-box-b {
    display: none;
  }
}

#root {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.text-green {
  color: #53972c;
}

.text-primary {
  color: #e30020 !important;
}

.bg-purple {
  background-color: #e30020;
}

.login-bg {
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

@media screen and (min-width: 768px) {
  .login-bg {
    height: 100%;
    width: 100%;
  }
}

.doctor-vector {
  width: auto;
  height: calc(100vh - 200px);
}

.logo {
  width: 300px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.mini-logo {
  width: 300px;
}

.bg-light-purple {
  background-color: #e9e8f1 !important;
}

.bg-light-green {
  background-color: #f4fbea;
}

.card-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  object-fit: cover;
}

.card-content {
  z-index: 1;
  position: absolute;
  height: 400px;
  width: 100%;
}

.h-screen {
  height: 100vh !important;
}

.divider {
  border-top: 2px solid #fff;
}

.bg-gray {
  background-color: #3b2063;
}

a {
  text-decoration: none;
}

.MuiOutlinedInput-root {
  background-color: #fff;
}

.text-light-gray {
  color: #a4a4a4;
}

.text-reddish {
  color: #e30020;
}

.signup-button {
  /* background: linear-gradient(31deg,
      rgba(80, 23, 144, 1) 0%,
      rgba(62, 112, 161, 1) 100%); */
  /* background-color: #E30020; */
  /* background: linear-gradient(135deg, rgb(102, 153, 255) 0%, rgb(255, 51, 102) 100%) !important */
  background: linear-gradient(98.67deg, #ff4c65, #e30020);
}

.active-plan {
  background-color: #e30020;
  /* background-color: #fff; */
}

.text-green {
  color: #2dc937;
}

.text-cancel {
  color: #81a7ba !important;
  text-decoration: line-through;
}

.feature-list {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.lower-element {
  height: 30%;
  width: 90px;
  /* background-color: #5d5fef; */
  background: linear-gradient(98.67deg, #9371db -33.77%, #703ddb 72.13%);
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 1.375rem;
  border-bottom-left-radius: 8.75rem;
}

.upper-element {
  height: 30%;
  width: 90px;
  /* background-color: #5d5fef; */
  background: linear-gradient(98.67deg, #9371db -33.77%, #703ddb 72.13%);
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 1.375rem;
  border-top-right-radius: 8.75rem;
  z-index: 0;
}

.auth-link {
  color: #fff;
}

i {
  color: #9abc66;
  font-size: 50px;
  line-height: 100px;
  margin-left: 25px;
}

/* .auth-link:hover {
  color: #E30020;
} */

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.gen-btn:hover {
  font-size: 14px;
}

.history-wrapper .nav-link {
  color: #e30020;
}

.option-wrapper {
  border: 1px solid #e3002020;
  background-color: #e3002020;
}

.option-wrapper .heading {
  font-weight: 300;
}

.option-wrapper:hover .heading {
  font-weight: 500;
}

.option-wrapper:hover {
  border: 1px solid #e30020;
  background-color: #e3002045;
}

.stepper-wrapper .label {
  font-weight: 300;
}

.MuiSelect-outlined {
  padding: 15px 14px !important;
}

.steps-content {
  height: 87px;
}

.gradient-text {
  background: linear-gradient(90deg, #ff4c65, #e30020);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-color {
  background: linear-gradient(90deg, #ff4c65, #e30020);
}

.rounded-full-img {
  border-radius: 50%;
  object-fit: cover;
  max-width: 100%;
  width: 85px;
  height: 85px;
}

/* :is(.MuiInputBase-colorPrimary.Mui-focused input,.MuiInputBase-colorPrimary.Mui-focused){
  border: 1px solid #E30020;
} */
/* :is(.MuiInputBase-colorPrimary.Mui-focused input, .MuiInputBase-colorPrimary.Mui-focused) {
  border: 1px solid #E30020;
  outline: none;
} */

.suggested-tabs-style {
  background: #fff;
  padding: 7px 10px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  color: #fff;
  cursor: pointer;
  border: 0;
  box-shadow: 0px 0px 40px 20px rgba(0, 0, 0, 0.02);
}

:is(.suggested-tabs-style:hover, .suggested-tabs-style.selected-tab) {
  background: linear-gradient(90deg, #ff4c65, #e30020);
}

:is(
    .suggested-tabs-style:hover > div,
    .suggested-tabs-style.selected-tab > div
  ) {
  color: #fff !important;
}

.qutions-field {
  background: transparent;
  border: 1px solid #dee2e6;
}

/* Container styling */
.multi-select-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: 2.9rem;
}

/* Typography for label */
.multi-select-container p {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
}

/* Option wrapper */
.option-item {
  display: flex;
  align-items: center;
  /*gap: 0.5rem;*/
  margin-top: 0.5rem;
  direction: ltr;
  /* Will be dynamically changed based on language */
}

/* Checkbox and label styling */
.option-checkbox {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.option-label {
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: auto 0;
}

/* RTL handling */
.option-rtl {
  flex-direction: row-reverse;
}

/* Add button styling */
.add-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  width: fit-content;
  font-weight: bold;
  background: linear-gradient(
    90deg,
    rgb(255, 76, 101),
    rgb(227, 0, 32)
  ) !important;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.displayNone{
    display: none !important;
}

.suggestedResponseBox {
  animation: slideIn 1s ease forwards;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%); /* Start off-screen to the left */
    opacity: 0; /* Start transparent */
  }
  100% {
    transform: translateX(0); /* Move to its final position */
    opacity: 1; /* End fully visible */
  }
}

.add-button:hover {
  background-color: #0056b3;
}

.border-bottom-dashed {
  border-bottom: 2px dashed #000;
}

.chipContainerMultiselect .MuiButtonBase-root.MuiChip-filled {
  margin-top: 0.3rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.mui-select-search .MuiOutlinedInput-root {
  padding: 0.55rem 0.75rem;
  border-radius: 0.375rem;
  color: #939393;
  font-size: 1.3rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -ms-border-radius: 0.375rem;
  -o-border-radius: 0.375rem;
}

.mui-select-search.multi-selected-value .MuiOutlinedInput-root {
  padding: 4px 12px !important;
}

.mui-select-search
  .MuiOutlinedInput-root
  :is(fieldset, fieldset:focus, fieldset:hover) {
  border: 1px solid #e2e5ed;
}

.mui-select-search.mui-select-error
  .MuiOutlinedInput-root
  :is(fieldset, fieldset:focus, fieldset:hover) {
  border: 1px solid var(--error-or-danger-color);
}

.mui-select-search .MuiOutlinedInput-root input {
  padding: 0 !important;
  font-family: "Roboto", sans-serif;
  color: #707070 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.1px;
  min-width: 0 !important;
}

.mui-select-search button svg {
  display: none;
}
